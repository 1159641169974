var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Banna", { attrs: { Banna: _vm.Banna } }),
      _vm.detailsContent
        ? _c("div", { staticClass: "details" }, [
            _c("div", { staticClass: "adapt" }, [
              _c("div", {
                staticClass: "details-bj",
                style: {
                  backgroundImage: "url(" + _vm.detailsContent.thumb + ")",
                  backgroundSize: "cover"
                }
              }),
              _c("div", { staticClass: "position" })
            ]),
            _c("div", { staticClass: "details-describe" }, [
              _c("div", { staticClass: "details-title" }, [
                _vm._v(_vm._s(_vm.detailsContent.cate_name))
              ]),
              _c("div", {
                staticClass: "details-content",
                domProps: { innerHTML: _vm._s(_vm.detailsContent.description) }
              })
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "related" },
        [
          _c(
            "a-carousel",
            {
              attrs: { arrows: "", dots: false, effect: "fade" },
              scopedSlots: _vm._u([
                {
                  key: "prevArrow",
                  fn: function(props) {
                    return _c(
                      "div",
                      {
                        staticClass: "custom-slick-arrow",
                        staticStyle: { left: "-20px", zIndex: "1" }
                      },
                      [_c("a-icon", { attrs: { type: "left-circle" } })],
                      1
                    )
                  }
                },
                {
                  key: "nextArrow",
                  fn: function(props) {
                    return _c(
                      "div",
                      {
                        staticClass: "custom-slick-arrow",
                        staticStyle: { right: "-20px" }
                      },
                      [_c("a-icon", { attrs: { type: "right-circle" } })],
                      1
                    )
                  }
                }
              ])
            },
            _vm._l(_vm.carousel, function(items, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex" },
                _vm._l(items, function(item) {
                  return _c("div", { key: item.id, staticClass: "flex-item" }, [
                    _c("div", { staticClass: "adapt" }, [
                      _c("div", {
                        staticClass: "item-bg",
                        style: {
                          background: "url(" + item.thumb + " )",
                          backgroundSize: "cover"
                        }
                      }),
                      _c("div", { staticClass: "position" })
                    ]),
                    _c("div", { staticClass: "item-title" }, [
                      _vm._v(_vm._s(item.title))
                    ])
                  ])
                }),
                0
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }