<template>
  <div>
    <Banna :Banna="Banna" />
    <div class="details" v-if="detailsContent">
      <div class="adapt">
        <div
          class="details-bj"
          :style="{ backgroundImage: 'url(' + detailsContent.thumb + ')', backgroundSize: 'cover' }"
        ></div>
        <div class="position"></div>
      </div>
      <div class="details-describe">
        <div class="details-title">{{ detailsContent.cate_name }}</div>
        <div class="details-content" v-html="detailsContent.description"></div>
      </div>
    </div>
    <div class="related">
      <a-carousel arrows :dots="false" effect="fade">
        <div
          slot="prevArrow"
          slot-scope=" props "
          class="custom-slick-arrow"
          style="left: -20px;zIndex: 1"
        >
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: -20px">
          <a-icon type="right-circle" />
        </div>
        <div class="flex" v-for="(items,index) in carousel" :key="index">
          <div class="flex-item" v-for="item in items" :key="item.id">
            <div class="adapt">
              <div
                class="item-bg"
                :style="{ background: 'url(' + item.thumb + ' )', backgroundSize: 'cover' }"
              ></div>
              <div class="position"></div>
            </div>
            <div class="item-title">{{item.title}}</div>
          </div>
        </div>
      </a-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      detailsContent: {
        thumb: '',
        title: '',
        content: '',
      },
      carousel: [],
      Banna: {},
    };
  },
  created() {},
  mounted() {
    this.details();
    this.isCarousel();
    this.getBanna();
  },
  methods: {
    details() {
      return this.$request(Apis.aboutUs.ABOUT).then(data => {
        this.detailsContent = data.data.data;
      });
    },
    isCarousel() {
      return this.$request(Apis.aboutUs.ARTRES).then(data => {
        let _data = data.data.data;
        for (var i = 0; i < _data.length; i += 3) {
          this.carousel.push(_data.slice(i, i + 3));
        }
      });
    },
    getBanna() {
      return this.$request(Apis.home.DATUS, { data: { id: '22' } })
        .then(data => {
          this.Banna = data.data.data;
          console.log(this.Banna);
        })
        .finally(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
/* For demo */
.related {
  margin: 30px auto;
  .ant-carousel {
    width: 90%;
    margin: auto;
    .custom-slick-arrow {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #fff;
      background-color: rgba(31, 45, 61, 0.11);
    }
    .custom-slick-arrow:before {
      display: none;
    }
    .custom-slick-arrow:hover {
      opacity: 0.5;
    }

    .slick-slide {
      .flex {
        display: flex !important;
        justify-content: space-around;
        align-items: center;
        .flex-item {
          width: 30%;
          .adapt {
            position: relative;
            width: 100%;
            .item-bg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #ffcc00;
            }
            .position {
              padding-bottom: 100%;
            }
          }

          .item-title {
            text-align: center;
            color: #fff;
            margin: 10px auto;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.details {
  width: 90%;
  margin: 80px auto;
  display: flex;
  justify-content: space-between;
  .adapt {
    position: relative;
    width: 35%;
    .details-bj {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      background: #ffcc00;
    }
    .position {
      padding-bottom: 100%;
    }
  }

  .details-describe {
    width: 60%;
    .details-title {
      text-align: center;
      font-size: 20px;
      color: #fff;
      margin: 10px 0;
    }
    .details-content {
      color: #fff;
      font-size: 16px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
